import React, { Fragment, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Outlet, useParams, useNavigate } from 'react-router-dom'

import SubMenu from '../../UI/SubMenu/SubMenu'
import { uiConstants } from '../../../constants'
import {
  pageDelete,
  licenseLoad,
  addNotification
} from '../../../redux/actions'

const PageLayout = ({ license, auth, page }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const deleteHandler = () => {
    dispatch(pageDelete(id))
  }

  useEffect(() => {
    if (!license.init && auth.me) {
      dispatch(licenseLoad({ id }))
    }
  }, [auth.me, dispatch, id, license.init])

  useEffect(() => {
    if (!page.init) return

    const p = (page.list || []).find((p) => p._id === id)
    if (!p && !page.loading) {
      navigate('/pages')
      dispatch(
        addNotification(
          uiConstants.messages.page_not_found,
          uiConstants.notification.error
        )
      )
    }
  }, [dispatch, id, navigate, page.list, page.init, page.loading])

  return (
    <Fragment>
      <SubMenu id={id} links={uiConstants.subMenuPage} />
      <Outlet context={[deleteHandler, 'page', id]} />
    </Fragment>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(PageLayout)
