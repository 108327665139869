export const pageConstants = {
  PAGE_LOAD: '[Page] Load',
  PAGE_LOAD_SUCCESS: '[Page] Load success',
  PAGE_LOAD_FAILURE: '[Page] Load failure',
  PAGE_RESET: '[Page] Reset',
  PAGE_SAVE: '[Page] Save',
  PAGE_SAVE_SUCCESS: '[Page] Save success',
  PAGE_SAVE_FAILURE: '[Page] Save failure',
  PAGE_DELETE: '[Page] Delete',
  PAGE_DELETE_SUCCESS: '[Page] Delete success',
  PAGE_DELETE_FAILURE: '[Page] Delete failure'
}
