import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAI8a8b0wZekXF9PwB2w-GjAb_nMRTiaZg',
  authDomain: 'nuketown-397819.firebaseapp.com',
  projectId: 'nuketown-397819',
  storageBucket: 'nuketown-397819.appspot.com',
  messagingSenderId: '181177556117',
  appId: '1:181177556117:web:4469ddcd7365454d798f9c',
  measurementId: 'G-PWYY4KRQXJ'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
