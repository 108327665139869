import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { alertLoad, pageLoad, endpointLoad } from '../../../../redux/actions'

const LoadComponents = ({ auth, page, endpoint, alert }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!page.init && auth.me) {
      dispatch(pageLoad())
    }
  }, [page.init, dispatch, auth.me])

  useEffect(() => {
    if (!endpoint.init && auth.me) {
      dispatch(endpointLoad())
    }
  }, [auth.me, dispatch, endpoint.init])

  useEffect(() => {
    if (!alert.init && auth.me) {
      dispatch(alertLoad())
    }
  }, [alert.init, auth.me, dispatch])

  return null
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(LoadComponents)
