import { pageConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  list: null,
  init: false,
  updating: false
}

export default function page(state = initialState, action) {
  switch (action.type) {
    case pageConstants.PAGE_LOAD:
      return {
        ...state,
        loading: true,
        list: null,
        init: true
      }
    case pageConstants.PAGE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        count: action.payload.count
      }
    case pageConstants.PAGE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case pageConstants.PAGE_SAVE:
    case pageConstants.PAGE_DELETE:
      return {
        ...state,
        updating: true
      }
    case pageConstants.PAGE_SAVE_SUCCESS:
      const list = state.list.find((x) => x._id === action.payload._id)
        ? state.list.map((x) => {
            return x._id === action.payload._id ? action.payload : x
          })
        : [...state.list, action.payload]
      return {
        ...state,
        updating: false,
        list,
        count: list.count
      }
    case pageConstants.PAGE_SAVE_FAILURE:
    case pageConstants.PAGE_DELETE_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload
      }
    case pageConstants.PAGE_DELETE_SUCCESS:
      return {
        ...state,
        updating: false,
        list: state.list.filter((x) => x._id !== action.payload),
        count: state.count - 1
      }
    case pageConstants.PAGE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
