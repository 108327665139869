import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  endpointLoadSuccess,
  endpointLoadFailure,
  endpointSaveSuccess,
  endpointSaveFailure,
  redirect,
  addNotification,
  endpointDeleteFailure,
  endpointDeleteSuccess
} from '../actions'
import { uiConstants } from '../../constants'
import { uiHelper } from '../../helpers'

export function* endpointLoadSaga() {
  try {
    const result = yield axios.get(uris.endpoint)
    yield put(endpointLoadSuccess(result.data))
  } catch (error) {
    yield put(endpointLoadFailure(error))
  }
}

export function* endpointSaveSaga(action) {
  try {
    const body = { ...action.payload }
    let url = uris.endpoint
    let method = 'post'
    if (action.payload.id !== null) {
      url += `/${action.payload.id}`
      method = 'put'
    }
    delete body.id
    const result = yield axios({
      method,
      url,
      data: body
    })
    yield put(endpointSaveSuccess(result.data))

    if (!action.payload.id) {
      yield put(redirect(`/pages/${body.page}/endpoints`))
    }
    yield put(
      addNotification(
        action.payload.id !== null
          ? uiConstants.messages.endpoint_updated
          : uiConstants.messages.endpoint_created
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(endpointSaveFailure(error))
  }
}

export function* endpointDeleteSaga(action) {
  try {
    const { id, pid } = action.payload

    yield axios.delete(`${uris.endpoint}/${pid}`)
    yield put(redirect(`/pages/${id}/endpoints`))
    yield put(endpointDeleteSuccess(pid))
    yield put(addNotification(uiConstants.messages.endpoint_deleted))
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(endpointDeleteFailure(error))
  }
}
