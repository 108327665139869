import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  licenseLoadSuccess,
  licenseLoadFailure,
  licenseSaveSuccess,
  licenseSaveFailure,
  redirect,
  addNotification
} from '../actions'
import { uiConstants } from '../../constants'
import { uiHelper } from '../../helpers'

export function* licenseLoadSaga(action) {
  try {
    const { id } = action.payload
    const result = yield axios.get(`${uris.license}/${id}`)
    yield put(licenseLoadSuccess(result.data))
  } catch (error) {
    yield put(licenseLoadFailure(error))
  }
}

export function* licenseSaveSaga(action) {
  try {
    const body = { ...action.payload }
    let url = uris.license
    if (action.payload.id !== null) {
      url += `/${action.payload.id}`
    }
    delete body.id
    const result = yield axios.post(url, body)
    yield put(licenseSaveSuccess(result.data))

    if (!action.payload.id) {
      yield put(redirect(`/pages/${body.page}/licenses`))
    }
    yield put(addNotification(uiConstants.messages.license_updated))
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(licenseSaveFailure(error))
  }
}
