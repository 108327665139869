import axios from 'axios'
import uris from './uris'
import { store } from './redux/store'
import { pageReset, endpointReset, redirect } from './redux/actions'
import { uiConstants } from './constants'

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = uris.apiBase
axiosInstance.defaults.timeout = 30000

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      Accepted: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers
    }
    config.headers.Authorization = store.getState().auth?.me?.accessToken ?? ''
    config.headers.uid = store.getState().auth?.me?.uid ?? ''

    return config
  },
  (error) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.response && !error.message) {
      const err = {
        response: {
          data: {
            message: uiConstants.messages.network_error,
            statusCode: 500
          }
        }
      }
      return Promise.reject(err)
    } else if (error.response?.status === 401) {
      store.dispatch(pageReset())
      store.dispatch(endpointReset())
      store.dispatch(redirect('/'))
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
