export const authConstants = {
  AUTH_LOGIN_GOOGLE: '[Auth] Login Google',
  AUTH_LOGIN_GITHUB: '[Auth] Login Github',
  AUTH_LOGIN_MICROSOFT: '[Auth] Login Microsoft',
  AUTH_LOGIN_SUCCESS: '[Auth] Login success',
  AUTH_LOGIN_FAILURE: '[Auth] Login error',
  AUTH_LOGOUT: '[Auth] Logout',
  AUTH_LOGOUT_SUCCESS: '[Auth] Logout success',
  AUTH_LOGOUT_FAILURE: '[Auth] Logout failure',
  AUTH_VALIDATE: '[Auth] Validate',
  AUTH_VALIDATE_FAILURE: '[Auth] Validate failure',
  AUTH_VALIDATE_SUCCESS: '[Auth] Validate success',
  AUTH_VALIDATE_SET_ME: '[Auth] Validate set me'
}
