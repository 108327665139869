import { pageConstants } from '../constants'

export const pageLoad = () => {
  return {
    type: pageConstants.PAGE_LOAD
  }
}

export const pageLoadSuccess = (payload) => {
  return {
    type: pageConstants.PAGE_LOAD_SUCCESS,
    payload
  }
}

export const pageLoadFailure = (payload) => {
  return {
    type: pageConstants.PAGE_LOAD_FAILURE,
    payload
  }
}

export const pageReset = () => {
  return {
    type: pageConstants.PAGE_RESET
  }
}

export const pageSave = (payload) => {
  return {
    type: pageConstants.PAGE_SAVE,
    payload
  }
}

export const pageSaveSuccess = (payload) => {
  return {
    type: pageConstants.PAGE_SAVE_SUCCESS,
    payload
  }
}

export const pageSaveFailure = (payload) => {
  return {
    type: pageConstants.PAGE_SAVE_FAILURE,
    payload
  }
}

export const pageDelete = (payload) => {
  return {
    type: pageConstants.PAGE_DELETE,
    payload
  }
}

export const pageDeleteSuccess = (payload) => {
  return {
    type: pageConstants.PAGE_DELETE_SUCCESS,
    payload
  }
}

export const pageDeleteFailure = (payload) => {
  return {
    type: pageConstants.PAGE_DELETE_FAILURE,
    payload
  }
}
