import { licenseConstants } from '../constants'

export const licenseLoad = (payload) => {
  return {
    type: licenseConstants.LICENSE_LOAD,
    payload
  }
}

export const licenseLoadSuccess = (payload) => {
  return {
    type: licenseConstants.LICENSE_LOAD_SUCCESS,
    payload
  }
}

export const licenseLoadFailure = (payload) => {
  return {
    type: licenseConstants.LICENSE_LOAD_FAILURE,
    payload
  }
}

export const licenseReset = () => {
  return {
    type: licenseConstants.LICENSE_RESET
  }
}

export const licenseSave = (payload) => {
  return {
    type: licenseConstants.LICENSE_SAVE,
    payload
  }
}

export const licenseSaveSuccess = (payload) => {
  return {
    type: licenseConstants.LICENSE_SAVE_SUCCESS,
    payload
  }
}

export const licenseSaveFailure = (payload) => {
  return {
    type: licenseConstants.LICENSE_SAVE_FAILURE,
    payload
  }
}
