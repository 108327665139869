import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import { validateAuth } from '../../../../redux/actions'

const CheckAuth = ({ auth }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!auth.validating && !auth.loading) {
      dispatch(validateAuth())
    }
  }, [auth.loading, auth.validating, dispatch])

  return <React.Fragment />
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(CheckAuth)
