import { endpointConstants } from '../constants'

export const endpointLoad = () => {
  return {
    type: endpointConstants.ENDPOINT_LOAD
  }
}

export const endpointLoadSuccess = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_LOAD_SUCCESS,
    payload
  }
}

export const endpointLoadFailure = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_LOAD_FAILURE,
    payload
  }
}

export const endpointReset = () => {
  return {
    type: endpointConstants.ENDPOINT_RESET
  }
}

export const endpointSave = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_SAVE,
    payload
  }
}

export const endpointSaveSuccess = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_SAVE_SUCCESS,
    payload
  }
}

export const endpointSaveFailure = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_SAVE_FAILURE,
    payload
  }
}

export const endpointDelete = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_DELETE,
    payload
  }
}

export const endpointDeleteSuccess = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_DELETE_SUCCESS,
    payload
  }
}

export const endpointDeleteFailure = (payload) => {
  return {
    type: endpointConstants.ENDPOINT_DELETE_FAILURE,
    payload
  }
}
