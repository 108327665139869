import React, { Suspense, lazy } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import history from './history'
import { store } from './redux/store'

import 'react-loading-skeleton/dist/skeleton.css'

import Layout from './components/Containers/Layout/Layout.jsx'
import AuthLayout from './components/Containers/AuthLayout/AuthLayout'
import PageLayout from './components/Containers/PageLayout/PageLayout'
import EndpointLayout from './components/Containers/EndpointLayout/EndpointLayout'
import AlertLayout from './components/Containers/AlertLayout/AlertLayout'
import PageLoader from './components/UI/PageLoader/PageLoader'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const DashboardLazy = lazy(() =>
  import('./components/Pages/Dashboard/Dashboard')
)
const NotFoundLazy = lazy(() => import('./components/Pages/NotFound/NotFound'))
const PageInfoLazy = lazy(() => import('./components/Pages/PageInfo/PageInfo'))
const EndpointsLazy = lazy(() =>
  import('./components/Pages/Endpoints/Endpoints')
)
const EndpointInfoLazy = lazy(() =>
  import('./components/Pages/EndpointInfo/EndpointInfo')
)
const AlertsLazy = lazy(() => import('./components/Pages/Alerts/Alerts'))
const LicenseLazy = lazy(() => import('./components/Pages/License/License'))
const DeleteLazy = lazy(() => import('./components/Pages/Delete/Delete'))
const AlertInfoLazy = lazy(() =>
  import('./components/Pages/AlertInfo/AlertInfo')
)

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />

              <Route element={<AuthLayout />}>
                <Route path='/pages' element={<DashboardLazy />} />
                <Route path='/pages/_new' element={<PageInfoLazy />} />
                <Route element={<PageLayout />}>
                  <Route
                    path='/pages/:id/endpoints'
                    element={<EndpointsLazy />}
                  />
                  <Route
                    path='/pages/:id/settings'
                    element={<PageInfoLazy />}
                  />
                  <Route path='/pages/:id/alerts' element={<AlertsLazy />} />
                  <Route path='/pages/:id/license' element={<LicenseLazy />} />
                  <Route path='/pages/:id/delete' element={<DeleteLazy />} />
                </Route>
                <Route
                  path='/pages/:id/endpoints/_new'
                  element={<EndpointInfoLazy />}
                />
                <Route
                  path='/pages/:id/alerts/_new'
                  element={<AlertInfoLazy />}
                />
                <Route element={<EndpointLayout />}>
                  <Route
                    path='/pages/:id/endpoints/:pid/settings'
                    element={<EndpointInfoLazy />}
                  />
                  <Route
                    path='/pages/:id/endpoints/:pid/delete'
                    element={<DeleteLazy />}
                  />
                </Route>
                <Route element={<AlertLayout />}>
                  <Route
                    path='/pages/:id/alerts/:pid/settings'
                    element={<AlertInfoLazy />}
                  />
                  <Route
                    path='/pages/:id/alerts/:pid/delete'
                    element={<DeleteLazy />}
                  />
                </Route>
              </Route>
              <Route path='*' element={<NotFoundLazy history={history} />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
