import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { connect, useDispatch } from 'react-redux'

import css from './Layout.module.scss'
import NotificationQueue from '../../UI/NotificationQueue/NotificationQueue'
import Navigator from './Navigator/Navigator'
import CheckAuth from './CheckAuth/CheckAuth'
import ReduxLoader from './ReduxLoader/ReduxLoader'
import Logo from '../../../assets/logo_horizontal.svg'
import { licenseReset } from '../../../redux/actions'

const Layout = ({ license }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    if (license.init && pathname === '/pages') {
      dispatch(licenseReset())
    }
  }, [dispatch, license.init, pathname])

  return (
    <div className={css.Layout}>
      <CheckAuth />
      <Navigator />
      <Outlet />
      <NotificationQueue />
      <ReduxLoader />
      <Tooltip id='nuke' />
      <div className={css.Footer}>
        &copy;{new Date().getFullYear()} NukeTown.io -{' '}
        {document.head.querySelector('meta[name="build-version"]')['content']}
        <img src={Logo} alt='NukeTown' className={css.Logo} />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(Layout)
