import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth } from '../../firestore'
import axios from '../../axios-conf'

import uris from '../../uris'
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  setPersistence,
  onAuthStateChanged,
  signOut,
  signInWithPopup,
  GithubAuthProvider,
  OAuthProvider
} from 'firebase/auth'

import {
  loginFailure,
  loginSuccess,
  logoutUserFailure,
  logoutUserSuccess,
  validateAuthFailure,
  validateAuthSuccess,
  validateAuthSetMe,
  addNotification,
  redirect
} from '../actions'

export function* loginGoogleSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new GoogleAuthProvider()
          provider.addScope('https://www.googleapis.com/auth/userinfo.email')
          provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(error.message))
    yield put(addNotification(error.message, true))
  }
}

export function* loginGithubSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new GithubAuthProvider()
          provider.addScope('user')
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(error.message))
    yield put(addNotification(error.message, true))
  }
}

export function* loginMicrosoftSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          const provider = new OAuthProvider('microsoft.com')
          provider.addScope('mail.read')
          return signInWithPopup(auth, provider)
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
    }
  } catch (error) {
    yield put(loginFailure(error.message))
    yield put(addNotification(error.message, true))
  }
}

export function* validateAuthSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = onAuthStateChanged(auth, (result) => {
        if (result) {
          emit({
            uid: result.uid,
            displayName: result.displayName,
            accessToken: result.accessToken,
            photoURL: result.photoURL,
            email: result.email
          })
        } else {
          emit(false)
        }
      })
      return () => unsubscribe
    })
    while (true) {
      const user = yield take(listener)
      if (user) {
        yield put(validateAuthSetMe(user))
        yield axios.get(uris.auth)

        yield put(validateAuthSuccess())
        if (window.location.pathname === '/') {
          yield put(redirect(`/pages`))
        }
      } else {
        yield put(validateAuthFailure())
        if (
          window.location.pathname !== '/' &&
          window.location.pathname !== '/privacy'
        ) {
          yield put(redirect(`/`))
        }
      }
    }
  } catch (error) {
    yield put(validateAuthFailure())
    if (window.location.pathname !== '/') {
      yield put(redirect(`/`))
    }
  }
}

export function* logoutUserSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = signOut(auth)
        .then(() => {
          emit(true)
        })
        .catch((error) => {
          emit(error)
        })
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)

      yield put(logoutUserSuccess())
      yield put(redirect(`/`))
    }
  } catch (error) {
    yield put(logoutUserFailure())
    yield put(redirect(`/`))
  }
}
