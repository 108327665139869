const errorMessage = (error) => {
  try {
    return error.response?.data?.message || error.message
  } catch {
    return 'Generic Error'
  }
}

export const uiHelper = {
  errorMessage
}
