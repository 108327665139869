import { uiConstants } from '../constants'

const initialState = {
  menuIsOpen: false,
  qrcode: null,
  barcode: null,
  notificationQueue: []
}

export default function ui(state = initialState, action) {
  switch (action.type) {
    case uiConstants.PUSH_NOTIFICATION:
      return {
        ...state,
        notificationQueue: state.notificationQueue.concat(action.payload)
      }
    case uiConstants.REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationQueue: state.notificationQueue.filter(
          (n) => n.guid !== action.payload
        )
      }
    default:
      return state
  }
}
