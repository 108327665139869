import Discord from '../assets/alerts/discord.svg'
import Slack from '../assets/alerts/slack.svg'
import Teams from '../assets/alerts/teams.svg'
import Webhook from '../assets/alerts/webhook.svg'

export const uiConstants = {
  dateFormat: 'DD MMM YYYY',
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  hourFormat: 'HH:mm',
  dayOfWeek: '0',
  timeToDateInput: 'YYYY-MM-DD',
  messages: {
    page_updated: 'Page updated',
    page_created: 'Page created',
    page_deleted: 'Page deleted',
    endpoint_updated: 'Endpoint updated',
    endpoint_created: 'Endpoint created',
    endpoint_deleted: 'Endpoint deleted',
    alert_updated: 'Alert updated',
    alert_created: 'Alert created',
    alert_deleted: 'Alert deleted',
    license_updated: 'License updated',
    page_not_found: 'Page not found'
  },
  nav: [
    {
      to: '/dashboard',
      label: 'Dashboard'
    }
  ],
  subMenuPage: [
    {
      to: 'endpoints',
      label: 'Endpoints'
    },
    {
      to: 'alerts',
      label: 'Alerts'
    },
    {
      to: 'settings',
      label: 'Settings'
    },
    {
      to: 'license',
      label: 'License'
    },
    {
      to: 'delete',
      label: 'Delete'
    }
  ],
  subMenuEndpoint: [
    {
      to: 'settings',
      label: 'Settings'
    },
    {
      to: 'delete',
      label: 'Delete'
    }
  ],
  subMenuAlert: [
    {
      to: 'settings',
      label: 'Settings'
    },
    {
      to: 'delete',
      label: 'Delete'
    }
  ],
  pageInfo: [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      required: true,
      default: ''
    },
    {
      label: 'Url',
      name: 'url',
      type: 'text',
      placeholder: 'Url',
      required: true,
      validate: (value) => {
        return /^[A-Za-z0-9]*$/.test(value)
      },
      default: ''
    },
    {
      label: 'Custom url',
      name: 'customUrl',
      type: 'text',
      placeholder: 'Custom url',
      validate: (value) => {
        return value !== ''
          ? /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,}$/gm.test(
              value
            )
          : true
      },
      required: false,
      default: '',
      description:
        'Create a CNAME record with your domain provider and point it to nuketown-app.pages.dev'
    },
    {
      label: 'Logo Url',
      name: 'logo',
      type: 'url',
      placeholder: 'Logo',
      required: true,
      default: 'https://cdn.nuketown.io/logo_horizontal.svg'
    },
    {
      label: 'Header Color',
      name: 'header',
      type: 'color',
      placeholder: 'Header Color',
      required: true,
      default: '#93e3fd'
    },
    {
      label: 'Your Website',
      name: 'website',
      type: 'url',
      placeholder: 'Your Website',
      required: false
    },
    {
      label: 'Copyright',
      name: 'copyright',
      type: 'text',
      placeholder: 'Copyright',
      required: false
    },
    {
      label: 'Days of event history',
      name: 'events',
      type: 'select',
      required: false,
      options: [
        {
          label: '30',
          value: '30'
        },
        {
          label: '45',
          value: '45'
        },
        {
          label: '60',
          value: '60'
        },
        {
          label: '90',
          value: '90'
        }
      ]
    }
  ],
  endpointInfo: [
    {
      label: 'Group',
      name: 'group',
      type: 'text',
      placeholder: 'Group',
      required: true,
      default: ''
    },
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      required: true,
      default: ''
    },
    {
      label: 'Url',
      name: 'url',
      type: 'url',
      placeholder: 'Url',
      required: true,
      default: ''
    },
    {
      label: 'Method',
      name: 'method',
      type: 'select',
      required: true,
      default: 'GET',
      options: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        },
        {
          label: 'PUT',
          value: 'PUT'
        },
        {
          label: 'DELETE',
          value: 'DELETE'
        },
        {
          label: 'PATCH',
          value: 'PATCH'
        },
        {
          label: 'HEAD',
          value: 'HEAD'
        },
        {
          label: 'OPTIONS',
          value: 'OPTIONS'
        },
        {
          label: 'TRACE',
          value: 'TRACE'
        },
        {
          label: 'CONNECT',
          value: 'CONNECT'
        }
      ]
    },
    {
      label: 'Expected Statuses (separated by comma)',
      name: 'expectedStatuses',
      type: 'text',
      placeholder: 'Expected Statuses',
      required: true,
      default: '200'
    },
    {
      label: 'Scan interval',
      name: 'scanInterval',
      type: 'select',
      placeholder: 'Scan interval',
      required: true,
      default: 15 * 60,
      options: [
        {
          label: '5 minutes',
          value: 5 * 60
        },
        {
          label: '10 minutes',
          value: 10 * 60
        },
        {
          label: '15 minutes',
          value: 15 * 60
        },
        {
          label: '30 minutes',
          value: 30 * 60
        },
        {
          label: '1 hour',
          value: 60 * 60
        },
        {
          label: '2 hours',
          value: 2 * 60 * 60
        }
      ]
    },
    {
      label: 'Timeout (ms)',
      name: 'timeout',
      type: 'number',
      placeholder: 'Timeout',
      required: true,
      default: 5000
    },
    {
      label: 'Headers',
      name: 'headers',
      type: 'key-value'
    },
    {
      label: 'Body',
      name: 'body',
      type: 'textarea'
    },
    {
      label: 'Region',
      name: 'region',
      type: 'regions'
    }
  ],
  alertInfo: [
    {
      label: 'Name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
      required: true,
      default: ''
    },
    {
      label: 'Type',
      name: 'type',
      type: 'select',
      required: true,
      default: 'discord',
      options: [
        {
          label: 'Discord',
          value: 'discord'
        },
        {
          label: 'Slack',
          value: 'slack'
        },
        {
          label: 'Microsoft Teams',
          value: 'teams'
        },
        {
          label: 'Webhook',
          value: 'webhook'
        }
      ]
    },
    {
      label: 'Url',
      name: 'url',
      type: 'url',
      placeholder: 'Url',
      required: true,
      default: ''
    }
  ],
  notification: {
    info: 'Info',
    error: 'Error',
    warning: 'Warning',
    success: 'Success'
  },
  alertIcons: {
    discord: Discord,
    slack: Slack,
    teams: Teams,
    webhook: Webhook
  },
  regions: [
    {
      value: 'europe-north1',
      label: 'Finland (europe-north1)'
    },

    {
      value: 'europe-west9',
      label: 'Paris (europe-west9)'
    },
    {
      value: 'europe-southwest1',
      label: 'Madrid (europe-southwest1)'
    },
    {
      value: 'europe-central2',
      label: 'Warsaw (europe-central2)'
    },
    {
      value: 'us-central1',
      label: 'Iowa (us-central1)'
    },
    {
      value: 'northamerica-northeast1',
      label: 'Montreal (northamerica-northeast1)'
    },
    {
      value: 'us-west1',
      label: 'Oregon (us-west1)'
    }
  ]
}
