import { combineReducers } from 'redux'

import auth from './auth.reducer'
import redirect from './redirect.reducer'
import ui from './ui.reducer'
import page from './page.reducer'
import endpoint from './endpoint.reducer'
import alert from './alert.reducer'
import license from './license.reducer'

const rootReducer = combineReducers({
  auth,
  redirect,
  ui,
  page,
  endpoint,
  alert,
  license
})

export default rootReducer
