import moment from 'moment'

import { uiConstants } from '../constants'

export const timeHelper = {
  currentTime,
  startDayToday,
  timeToDate,
  timeToHour,
  getHours,
  dayOfWeekFromTime,
  timeToDateInput,
  dateInputToTime,
  timeToInputTime,
  dateHourInputToTime
}

function currentTime() {
  return moment().utc().unix()
}

function startDayToday() {
  return moment().utc().startOf('day').unix()
}

function timeToDate(d, empty = '-') {
  if (d === 0) {
    return empty
  }
  return moment.unix(d).format(uiConstants.dateFormat)
}

function timeToDateInput(d) {
  return moment.unix(d).format(uiConstants.timeToDateInput)
}

function dateInputToTime(d) {
  return moment.utc(d).startOf('day').unix()
}

function timeToHour(d) {
  return moment.unix(d).utc().format(uiConstants.hourFormat)
}

function getHours(start, end) {
  return (end - start) / 3600 + 'H'
}

function dayOfWeekFromTime(d) {
  switch (moment.unix(d).utc().day()) {
    case 1:
      return 'lunedì'
    case 2:
      return 'martedì'
    case 3:
      return 'mercoledì'
    case 4:
      return 'giovedì'
    case 5:
      return 'venerdì'
    case 6:
      return 'sabato'
    default:
      return 'domenica'
  }
}

function timeToInputTime(d) {
  const sd = moment.unix(d).startOf('day').utc().unix()
  const time = d - sd
  const hour = Math.floor(time / 3600)
  const min = time % 60
  return `${hour < 10 ? '0' : ''}${hour}:${min}${min < 10 ? '0' : ''}:00`
}

function dateHourInputToTime(date, time) {
  const startOfDay = dateInputToTime(date)
  const t = time.split(':')
  const h = parseInt(t[0]) * 3600
  const m = parseInt(t[1]) * 60
  return startOfDay + h + m
}
