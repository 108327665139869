import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import {
  watchAuth,
  watchPage,
  watchEndpoint,
  watchAlert,
  watchLicense
} from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production'
})

sagaMiddleware.run(watchAuth)
sagaMiddleware.run(watchPage)
sagaMiddleware.run(watchEndpoint)
sagaMiddleware.run(watchAlert)
sagaMiddleware.run(watchLicense)
