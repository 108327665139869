import { takeEvery } from 'redux-saga/effects'
import {
  authConstants,
  pageConstants,
  endpointConstants,
  alertConstants,
  licenseConstants
} from '../constants'
import {
  loginGithubSaga,
  loginGoogleSaga,
  loginMicrosoftSaga,
  validateAuthSaga,
  logoutUserSaga
} from './auth.sagas'
import { pageLoadSaga, pageSaveSaga, pageDeleteSaga } from './page.sagas'
import {
  endpointLoadSaga,
  endpointSaveSaga,
  endpointDeleteSaga
} from './endpoint.sagas'
import { alertLoadSaga, alertSaveSaga, alertDeleteSaga } from './alert.sagas'
import { licenseLoadSaga, licenseSaveSaga } from './license.sagas'

export function* watchAuth() {
  yield takeEvery(authConstants.AUTH_LOGIN_GOOGLE, loginGoogleSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_GITHUB, loginGithubSaga)
  yield takeEvery(authConstants.AUTH_LOGIN_MICROSOFT, loginMicrosoftSaga)
  yield takeEvery(authConstants.AUTH_VALIDATE, validateAuthSaga)
  yield takeEvery(authConstants.AUTH_LOGOUT, logoutUserSaga)
}

export function* watchPage() {
  yield takeEvery(pageConstants.PAGE_LOAD, pageLoadSaga)
  yield takeEvery(pageConstants.PAGE_SAVE, pageSaveSaga)
  yield takeEvery(pageConstants.PAGE_DELETE, pageDeleteSaga)
}

export function* watchEndpoint() {
  yield takeEvery(endpointConstants.ENDPOINT_LOAD, endpointLoadSaga)
  yield takeEvery(endpointConstants.ENDPOINT_SAVE, endpointSaveSaga)
  yield takeEvery(endpointConstants.ENDPOINT_DELETE, endpointDeleteSaga)
}

export function* watchAlert() {
  yield takeEvery(alertConstants.ALERT_LOAD, alertLoadSaga)
  yield takeEvery(alertConstants.ALERT_SAVE, alertSaveSaga)
  yield takeEvery(alertConstants.ALERT_DELETE, alertDeleteSaga)
}

export function* watchLicense() {
  yield takeEvery(licenseConstants.LICENSE_LOAD, licenseLoadSaga)
  yield takeEvery(licenseConstants.LICENSE_SAVE, licenseSaveSaga)
}
