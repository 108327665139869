import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  alertLoadSuccess,
  alertLoadFailure,
  alertSaveSuccess,
  alertSaveFailure,
  redirect,
  addNotification,
  alertDeleteFailure,
  alertDeleteSuccess
} from '../actions'
import { uiConstants } from '../../constants'
import { uiHelper } from '../../helpers'

export function* alertLoadSaga() {
  try {
    const result = yield axios.get(uris.alert)
    yield put(alertLoadSuccess(result.data))
  } catch (error) {
    yield put(alertLoadFailure(error))
  }
}

export function* alertSaveSaga(action) {
  try {
    const body = { ...action.payload }
    let url = uris.alert
    let method = 'post'
    if (action.payload.id !== null) {
      url += `/${action.payload.id}`
      method = 'put'
    }
    delete body.id
    const result = yield axios({
      method,
      url,
      data: body
    })
    yield put(alertSaveSuccess(result.data))

    if (!action.payload.id) {
      yield put(redirect(`/pages/${body.page}/alerts`))
    }
    yield put(
      addNotification(
        action.payload.id !== null
          ? uiConstants.messages.alert_updated
          : uiConstants.messages.alert_created
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(alertSaveFailure(error))
  }
}

export function* alertDeleteSaga(action) {
  try {
    const { id, pid } = action.payload

    yield axios.delete(`${uris.alert}/${pid}`)
    yield put(redirect(`/pages/${id}/alerts`))
    yield put(alertDeleteSuccess(pid))
    yield put(addNotification(uiConstants.messages.alert_deleted))
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(alertDeleteFailure(error))
  }
}
