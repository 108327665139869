import { licenseConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  current: null,
  init: false
}

export default function license(state = initialState, action) {
  switch (action.type) {
    case licenseConstants.LICENSE_LOAD:
      return {
        ...state,
        loading: true,
        current: null,
        init: true
      }
    case licenseConstants.LICENSE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload
      }
    case licenseConstants.LICENSE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case licenseConstants.LICENSE_SAVE:
      return {
        ...state,
        loading: true
      }
    case licenseConstants.LICENSE_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        current: action.payload
      }
    case licenseConstants.LICENSE_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case licenseConstants.LICENSE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
