import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { alertLoad, pageLoad, endpointLoad } from '../../../../redux/actions'

const Tools = ({ pathname, paths, pages }) => {
  const dispatch = useDispatch()

  const loadPageEndpoints = () => {
    dispatch(pageLoad())
    dispatch(endpointLoad())
    dispatch(alertLoad())
  }

  const render = () => {
    const l = []
    if (!pages) return l

    if (paths.length === 3) {
      const p = pages.find((x) => x._id === paths[1])

      if (!p) return []

      const url =
        p.customUrl !== ''
          ? `https://${p.customUrl}`
          : `https://${p?.url?.toLowerCase()}.nuketown.io`
      l.push(
        <a
          href={url}
          target='_blank'
          rel='noreferrer'
          data-tooltip-id='nuke'
          data-tooltip-content={url}
        >
          <i className='fa-solid fa-up-right-from-square'></i>
        </a>
      )
    }

    if (paths.length === 1) {
      l.push(
        <Link
          to='/pages/_new'
          data-tooltip-id='nuke'
          data-tooltip-content='New Page'
        >
          <i className='fa-solid fa-plus'></i>
        </Link>
      )
      l.push(
        <button
          onClick={loadPageEndpoints}
          data-tooltip-id='nuke'
          data-tooltip-content='Reload'
          aria-label='Reload'
        >
          <i className='fa-solid fa-rotate'></i>
        </button>
      )
    }
    if (paths.length === 2 && paths[paths.length - 1] === '_new') {
      l.push(
        <Link to='/pages' data-tooltip-id='nuke' data-tooltip-content='Back'>
          <i className='fa-solid fa-arrow-left'></i>
        </Link>
      )
    }
    if (
      paths.length === 3 &&
      (paths[2] === 'endpoints' || paths[2] === 'alerts')
    ) {
      let p = paths[2] === 'endpoints' ? 'Endpoint' : 'Alert'

      l.push(
        <Link
          to={`${pathname}/_new`}
          data-tooltip-id='nuke'
          data-tooltip-content={`New ${p}`}
        >
          <i className='fa-solid fa-plus'></i>
        </Link>
      )
      if (paths[2] === 'endpoints') {
        l.push(
          <button
            onClick={() => dispatch(endpointLoad())}
            data-tooltip-id='nuke'
            data-tooltip-content='Reload'
            aria-label='Reload'
          >
            <i className='fa-solid fa-rotate'></i>
          </button>
        )
      }
      if (paths[2] === 'alerts') {
        l.push(
          <button
            onClick={() => dispatch(alertLoad())}
            data-tooltip-id='nuke'
            data-tooltip-content='Reload'
            aria-label='Reload'
          >
            <i className='fa-solid fa-rotate'></i>
          </button>
        )
      }
    }
    if (paths.length === 5) {
      l.push(
        <Link
          to={`/pages/${paths[1]}/${paths[2]}`}
          data-tooltip-id='nuke'
          data-tooltip-content='Back'
        >
          <i className='fa-solid fa-arrow-left'></i>
        </Link>
      )
    }

    return l
  }

  return render().map((x, key) => <li key={key}>{x}</li>)
}

export default Tools
