import React from 'react'
import { Link } from 'react-router-dom'

import css from './Breadcrumbs.module.scss'

const Breadcrumbs = ({ paths, pages, endpoints, alerts }) => {
  const title = () => {
    const l = []
    if (!pages || !endpoints || !alerts) return l

    if (paths.length === 1) {
      l.push(<span>Pages</span>)
    } else {
      l.push(<Link to='/pages'>Pages</Link>)
    }
    if (paths[1] === '_new') {
      l.push(<span>New Page</span>)
    }
    if (paths.length > 2) {
      l.push(<span>{pages.find((x) => x._id === paths[1])?.name}</span>)
      if (paths.length < 4) {
        l.push(<span> {paths[2]}</span>)
      }
    }
    if (paths.length >= 4) {
      l.push(<Link to={`/pages/${paths[1]}/${paths[2]}`}>{paths[2]}</Link>)
      if (paths[3] === '_new') {
        if (paths[2] === 'endpoints') {
          l.push(<span>New Endpoint</span>)
        }
        if (paths[2] === 'alerts') {
          l.push(<span>New Alert</span>)
        }
      } else {
        if (paths[2] === 'endpoints') {
          l.push(<span>{endpoints.find((x) => x._id === paths[3])?.name}</span>)
        }
        if (paths[2] === 'alerts') {
          l.push(<span>{alerts.find((x) => x._id === paths[3])?.name}</span>)
        }
      }
    }
    if (paths.length === 5) {
      l.push(<span>{paths[4]}</span>)
    }

    return l
  }

  return (
    <ul className={css.UlLinks}>
      {title().map((x, key) => (
        <li key={key}>{x}</li>
      ))}
    </ul>
  )
}

export default Breadcrumbs
