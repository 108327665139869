import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import PageLoader from '../../../UI/PageLoader/PageLoader'
import AxiosLoader from '../../../UI/AxiosLoader/AxiosLoader'

const ReduxLoader = ({ auth, page, endpoint, alert, license }) => {
  return (
    <Fragment>
      {auth.loading && <PageLoader />}
      {(page.updating ||
        endpoint.updating ||
        alert.updating ||
        license.loading) && <AxiosLoader />}
    </Fragment>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(ReduxLoader)
