import React from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import Breadcrumbs from './Breadcrumbs/Breadcrumbs'
import css from './Card.module.scss'

import Tools from './Tools/Tools'

const Card = ({ children, page, endpoint, alert }) => {
  const { pathname } = useLocation()
  const paths = pathname.split('/').filter((x) => x !== '')

  return (
    <div className={css.Container}>
      <div className={css.Header}>
        {
          <Breadcrumbs
            pathname={pathname}
            paths={paths}
            pages={page.list}
            endpoints={endpoint.list}
            alerts={alert.list}
          />
        }
        <ul className={css.UlHeader}>
          <Tools pathname={pathname} paths={paths} pages={page.list} />
        </ul>
      </div>
      <div className={css.Body}>{children}</div>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(Card)
