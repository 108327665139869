import { NavLink } from 'react-router-dom'

import css from './SubMenu.module.scss'

const SubMenu = ({ id, pid, links, paths }) => (
  <ul className={css.UlSubMenu}>
    {links.map((item) => (
      <li key={item.label} className={css.LiSubMenu}>
        <NavLink
          to={`/pages/${id}/${pid ? `${paths[2]}/${pid}/` : ''}${item.to}`}
          className={({ isActive }) => (isActive ? css.LinkActive : '')}
        >
          {item.label}
        </NavLink>
      </li>
    ))}
  </ul>
)

export default SubMenu
