import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Outlet, useParams, useLocation } from 'react-router-dom'

import SubMenu from '../../UI/SubMenu/SubMenu'
import { uiConstants } from '../../../constants'
import { endpointDelete } from '../../../redux/actions'

const EndpointLayout = () => {
  const dispatch = useDispatch()
  const { id, pid } = useParams()
  const { pathname } = useLocation()
  const paths = pathname.split('/').filter((x) => x !== '')

  const deleteHandler = () => {
    dispatch(endpointDelete({ id, pid }))
  }

  return (
    <Fragment>
      <SubMenu
        id={id}
        pid={pid}
        links={uiConstants.subMenuEndpoint}
        paths={paths}
      />
      <Outlet context={[deleteHandler, 'endpoint', id, pid]} />
    </Fragment>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(EndpointLayout)
