import React from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

import css from './AuthLayout.module.scss'
import Menu from './Menu/Menu'
import LoadComponents from './LoadComponents/LoadComponents'
import Card from '../../UI/Card/Card'

const AuthLayout = ({ auth }) => (
  <div className={css.AuthLayout}>
    <Menu me={auth.me} />
    <LoadComponents />
    <main className={css.Main}>
      <div className={css.Container}>
        <Card>
          <Outlet />
        </Card>
      </div>
    </main>
  </div>
)

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps)(AuthLayout)
