import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import {
  pageLoadSuccess,
  pageLoadFailure,
  pageSaveSuccess,
  pageSaveFailure,
  redirect,
  addNotification,
  pageDeleteFailure,
  pageDeleteSuccess
} from '../actions'
import { uiConstants } from '../../constants'
import { uiHelper } from '../../helpers'

export function* pageLoadSaga() {
  try {
    const result = yield axios.get(uris.page)
    yield put(pageLoadSuccess(result.data))
  } catch (error) {
    yield put(pageLoadFailure(error))
  }
}

export function* pageSaveSaga(action) {
  try {
    const body = { ...action.payload }
    let url = uris.page
    let method = 'post'
    if (action.payload.id !== null) {
      url += `/${action.payload.id}`
      method = 'put'
    }
    delete body.id
    const result = yield axios({
      method,
      url,
      data: body
    })
    yield put(pageSaveSuccess(result.data))

    if (!action.payload.id) {
      yield put(redirect(`/pages/${result.data._id}/endpoints`))
    }
    yield put(
      addNotification(
        action.payload.id !== null
          ? uiConstants.messages.page_updated
          : uiConstants.messages.page_created
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(pageSaveFailure(error))
  }
}

export function* pageDeleteSaga(action) {
  try {
    yield axios.delete(`${uris.page}/${action.payload}`)
    yield put(redirect('/pages'))
    yield put(pageDeleteSuccess(action.payload))
    yield put(addNotification(uiConstants.messages.page_deleted))
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
    yield put(pageDeleteFailure(error))
  }
}
