import { alertConstants } from '../constants'

export const alertLoad = () => {
  return {
    type: alertConstants.ALERT_LOAD
  }
}

export const alertLoadSuccess = (payload) => {
  return {
    type: alertConstants.ALERT_LOAD_SUCCESS,
    payload
  }
}

export const alertLoadFailure = (payload) => {
  return {
    type: alertConstants.ALERT_LOAD_FAILURE,
    payload
  }
}

export const alertReset = () => {
  return {
    type: alertConstants.ALERT_RESET
  }
}

export const alertSave = (payload) => {
  return {
    type: alertConstants.ALERT_SAVE,
    payload
  }
}

export const alertSaveSuccess = (payload) => {
  return {
    type: alertConstants.ALERT_SAVE_SUCCESS,
    payload
  }
}

export const alertSaveFailure = (payload) => {
  return {
    type: alertConstants.ALERT_SAVE_FAILURE,
    payload
  }
}

export const alertDelete = (payload) => {
  return {
    type: alertConstants.ALERT_DELETE,
    payload
  }
}

export const alertDeleteSuccess = (payload) => {
  return {
    type: alertConstants.ALERT_DELETE_SUCCESS,
    payload
  }
}

export const alertDeleteFailure = (payload) => {
  return {
    type: alertConstants.ALERT_DELETE_FAILURE,
    payload
  }
}
