export const alertConstants = {
  ALERT_LOAD: '[Alert] Load',
  ALERT_LOAD_SUCCESS: '[Alert] Load success',
  ALERT_LOAD_FAILURE: '[Alert] Load failure',
  ALERT_RESET: '[Alert] Reset',
  ALERT_SAVE: '[Alert] Save',
  ALERT_SAVE_SUCCESS: '[Alert] Save success',
  ALERT_SAVE_FAILURE: '[Alert] Save failure',
  ALERT_DELETE: '[Alert] Delete',
  ALERT_DELETE_SUCCESS: '[Alert] Delete success',
  ALERT_DELETE_FAILURE: '[Alert] Delete failure'
}
