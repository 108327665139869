import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../../../redux/actions'

import css from './Menu.module.scss'
import { Link } from 'react-router-dom'
import Logo from '../../../../assets/bomb.svg'

const Menu = ({ me }) => {
  const dispatch = useDispatch()
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    const listener = document.addEventListener('click', (e) => {
      const parent = e.target.parentElement
      if (!showMenu) return
      if (
        e.target?.className?.toLowerCase()?.startsWith('menu') ||
        parent?.className?.toLowerCase()?.startsWith('menu')
      )
        return
      setTimeout(() => {
        setShowMenu(false)
      }, 10)
    })
    return () => document.removeEventListener('click', listener)
  }, [showMenu])

  if (!me) return null

  return (
    <nav className={css.Nav}>
      <Link to='/pages' className={css.Brand}>
        <img src={Logo} alt='logo' />
      </Link>
      <button className={css.User} onClick={() => setShowMenu(!showMenu)} aria-label="Profile">
        <img src={me.photoURL} alt='profile' className={css.Avatar} />
      </button>
      {showMenu && (
        <div className={css.Menu}>
          <img src={me.photoURL} alt='profile' />
          <p>{me.displayName}</p>
          <p>{me.email}</p>

          <button
            id='logout'
            className={css.MenuButton}
            onClick={() => dispatch(logoutUser())}
          >
            <i className='fa-solid fa-right-from-bracket'></i> logout
          </button>
        </div>
      )}
    </nav>
  )
}

export default Menu
