const uris = {
  apiBase: process.env.REACT_APP_API_BASE,
  endpoint: 'endpoint',
  auth: 'auth',
  page: 'page',
  alert: 'alert',
  license: 'license'
}

export default uris
