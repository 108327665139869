export const endpointConstants = {
  ENDPOINT_LOAD: '[Endpoint] Load',
  ENDPOINT_LOAD_SUCCESS: '[Endpoint] Load success',
  ENDPOINT_LOAD_FAILURE: '[Endpoint] Load failure',
  ENDPOINT_RESET: '[Endpoint] Reset',
  ENDPOINT_SAVE: '[Endpoint] Save',
  ENDPOINT_SAVE_SUCCESS: '[Endpoint] Save success',
  ENDPOINT_SAVE_FAILURE: '[Endpoint] Save failure',
  ENDPOINT_DELETE: '[Endpoint] Delete',
  ENDPOINT_DELETE_SUCCESS: '[Endpoint] Delete success',
  ENDPOINT_DELETE_FAILURE: '[Endpoint] Delete failure'
}
